import { useState, useEffect, useRef } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { userService } from "../../services";
import Header from "./Header";
import { formValidation } from "../../services/validation.service";
import { categories, config } from "../../config/config";
import axios from "axios";
import Swal from 'sweetalert2'
import Loader from "./Loader";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { toast } from "react-toastify";

function SignupMain() {
  const [show, setShow] = useState(false);

  const [showPassword, setShowPassword] = useState(true);
  const [showCPassword, setShowCPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false)
  const [userType, setUserType] = useState("Professional")
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const togglePassword2 = () => {
    setShowCPassword(!showCPassword);
  };

  const handleCloseSocialMedia = () => setShowSocialMedia(false);

 

  const handleClose = () => {
    setShow(false)
    window.location.href = "/signin";
  };
  const handleShow = () => setShow(true);
  const [userDetail, setuserDetails] = useState({ email: "", password: "", cpassword: "", userType: "Professional", deviceInfo: { token: "", deviceType: "web" }, accountType: "" })
  const [formerrors, setformerrors] = useState({ email: "", password: "", cpassword: "", userType: "", deviceInfo: { token: "", deviceType: "web" } })
  const [ERR, setErrs] = useState()
  const [users, setUsers] = useState([]);

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  // api uer register
  function RegisterUser() {
    let params = {
      email: userDetail?.email,
      password: userDetail?.password,
      userType: userDetail?.userType,
      deviceInfo: userDetail.deviceInfo,
      cpassword: userDetail.cpassword,
      professionalType: userDetail.accountType,
      signUpType: 'Standard'
    };
      setIsLoading(true)
      userService.registerUser(params).then((res) => {
        if (res?.data?.status === 200) {
          handleShow()
        }
        if (res?.data?.status === 401)
          Swal.fire(
            'Warning',
            res?.data?.message,
            'warning'
          )
          setIsLoading(false)
      })
        .catch((error) => {
          setIsLoading(false)
          Swal.fire(
            'Error',
            "Some error occured.",
            'Error'
          )
        })
  
  }

  const [showbutton, setshowbutton] =useState(false)

  const resendVerification = () => {
    setshowbutton(true)
    userService
      .ResendEmailVerificationApi({
        email: userDetail?.email,
      })
      .then((res) => {
        Swal.fire("Success","Email Resent successfully","success").then((res) =>{
          window.location.href = "/signin";
        })
       
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Invalid request",
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: "Custom image",
        });
        setshowbutton(false)
      });
  };

  
  function responseGoogle(response) {
    if (response.profileObj) {
      const formData = {
        signUpType: "Google",
        screenNumber: 3,
        email: response.profileObj.email,
        fbId: "",
        deviceInfo: { token: "", deviceType: "Other" },
        appleId: "",
        metricsDate: getDate(),
      };

      let socialLoginData = {
        signUpType: "Google",
        screenNumber: 5,
        email: response.profileObj.email,
        fbId: "",
      };


      socialLogin(formData, socialLoginData);
    }
  }

  let userIDRef = useRef(null);
  
  const socialLogin = (params, socialLoginData) => {
    userService.socialLogin(params).then(function (response) {

      localStorage.removeItem('socialLogin');
      if (response.data.status == 200) {
        let data = response?.data?.data[0]?._id;
        userIDRef.current = data;
        if (response?.data?.data[0]?.userType == null) {
          setShowSocialMedia(true)
        }
        else{
        localStorage.setItem("token",response?.data?.token)
        localStorage.setItem('user_id', response.data.data[0]._id);
        localStorage.setItem("userType",response?.data?.data[0]?.userType)
        toast.success("Successfully Signed-In");
        if (response?.data?.data[0]?.ProfileCreated == 0 ) {
          window.location.href = "/create-profile"
        }
        else{
          window.location.href="/";
        }
        }

      } else if (response.data.status === 202) {
        localStorage.setItem('socialLogin', JSON.stringify(socialLoginData));
      } else {
        setIsLoading(false);
        toast.error("Some Error Occur",{
          toastId: 'success_draft_1',
        });
      }
    }).
   
    catch((error) => {
      setIsLoading(false);
        if(error?.response?.data?.status ==422){
        setIsLoading(false);
            setShow(true)
      }else{
        if (error?.response?.data.message == "Your account verification request has been rejected by admin. Please submit again." && error?.response?.data?.data[0]?.admin_status =="Rejected"){
          localStorage.setItem("token",error?.response?.data?.token)
          localStorage.setItem("userType", error?.response?.data?.data[0]?.userType)
          localStorage.setItem("user_id", error?.response?.data?.data[0]?._id);
          localStorage.setItem("user", JSON.stringify(error?.response?.data?.data[0]));
          Swal.fire("Warning",error?.response?.data?.message,"warning").then((res) =>{
            window.location.href="/create-profile";
          })
        }
        else
        {
          toast.error(error?.response?.data?.message ,{
            toastId: 'success_draft_1',
          });
        }
      }
      
    });
  }

  const responseFacebook = (response) => {
    if (!response.status) {
      const formData = new FormData();
      formData.append("signUpType", "Facebook");
      formData.append("screenNumber", 3);
      formData.append("email", response.email);
      formData.append("name", response.name);
      formData.append("fbId", response.id);
      formData.append("appleId", "");
      formData.append("metricsDate", getDate());
      let socialLoginData = {
        signUpType: "Facebook",
        screenNumber: 5,
        email: response.email,
        fbId: response.id,
      };
      socialLogin(formData, socialLoginData);
    }
  };


  function saveSocialLoginUserType(){
    if( userType =="Professional" && userDetail?.accountType == ""){
      Swal.fire("Warning","Please select account type.","warning");
    }
    else{
    let params ={
      user_id:userIDRef.current,
      user_type:userType,
      professional_type:userDetail?.accountType,
    }
    userService.SocialLogin_user_type(params).then((res) =>{
      if(res.data.status===200){
        localStorage.setItem("token",res?.data?.token)
        localStorage.setItem("userType",res?.data?.data?.userType)
        localStorage.setItem("user_id",res?.data?.data?._id)
        setShowSocialMedia(false)
        if (res?.data?.data?.ProfileCreated == 0 ) {
          window.location.href = "/create-profile"
        }
        else{
          window.location.href="/";
        }
     
      }
    })
    .catch((error) =>{
    })}
  }


  function getDate() {
    var date = new Date().getDate(); //Current Date
    var year = new Date().getFullYear(); //Current Year
    var month = new Date().getMonth() + 1;
    var day = new Date().getDay() + 1;
    return month + "-" + date + "-" + year;
  }

  const handleChange = (event) => {
    setuserDetails({ ...userDetail, accountType: event.target.value });
  };

  function handleSumit(e) {
    e.preventDefault();
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if(userDetail.userType =="Professional" && userDetail?.accountType ==""){
      Swal.fire("Warning","Please Select Your Account type","warning")
      return
    }
    else if(!userDetail.email || userDetail?.email.trimStart() ==""){
      Swal.fire("Warning","E-mail field can’t be empty.","warning")
      return
    }
    else if(!pattern.test(userDetail.email)){
      Swal.fire("Warning", 'Entered E-mail address is not valid.',"warning")
      return
    }
    else if(!userDetail.password || userDetail?.password.trimStart() ==""){
      Swal.fire("Warning",'Password field can’t be empty.',"warning")
      return
    }
    else if (userDetail.password && userDetail.password.length < 8  && userDetail.password.length <50 && userDetail.password !== "") {
      Swal.fire("Warning",'Password must be at least 8 characters.',"warning")
      return
    } 
   else if (!userDetail?.cpassword) {
      Swal.fire("Warning",'Confirm Password field can’t be empty.',"warning")
      return
    }
     else if (userDetail?.cpassword && userDetail?.cpassword.trimStart() == "") {
      Swal.fire("Warning",'Confirm Password field can’t be empty.',"warning")
      return
    }
     else if (userDetail?.cpassword !== userDetail.password) {
      Swal.fire("Warning",'Password and Confirm password doesn’t match.',"warning")
      return
    }
    else{
      RegisterUser()
    }
  }





  return (
    <div>
      <Header />
      {isLoading && <Loader />}
      
      <section className="bg_section signin-bg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <aside className="col-md-6 col-sm-6 pt-4">
              <div className="Signup_box">
                <h4>SIGN UP</h4>
                <p className="text-secondary">
                  Welcome to{" "}
                  <a href="#" className="higlight-text">
                    BIG4 Health
                  </a>{" "}
                  - where healthcare providers and clients meet to prevent and
                  reverse the BIG4: high blood pressure, diabetes, high
                  cholesterol, or obesity.
                </p>
                <Tabs
                  defaultActiveKey="Professional"
                  id="uncontrolled-tab-example"
                  className="my-3"
                  onSelect={(e) => {  setuserDetails({ ...userDetail, userType: e }) }}
                >
                  <Tab
                    eventKey="Professional"
                    title="Healthcare Provider"
                  >
                  </Tab>
                  <Tab eventKey="Client" title="Client">
                  </Tab>
                </Tabs>
                <form className="py-4">
                  <div className="row">
                    <aside className="col-md-12 mb-3 notranslate">
                      {userDetail?.userType == "Professional" && 
                      <div className="input_row">
                        <label>Select Account type</label>
                        <div className="input103">
                        <select
                          className=" w-100 select-main"
                          name="userType"
                          onChange={handleChange} value={userDetail.accountType}>
                              <option value={""}>Select account type</option>
                          {categories.map((category) => (
                            <option key={category} value={category}>
                              {category}
                            </option>
                          ))}
                        </select>
                        </div>
                      </div>}
                     
                    </aside>
                    <aside className="col-md-12 mb-3">
                      <div className="input_row">
                        <label>Email Address</label>
                        <input
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          className="input103 w-100"
                          onChange={(e) => inputChange(e)}
                        />

                      </div>
                     
                    </aside>
                    <aside className="col-md-12 mb-3">
                      <div className="input_row password">
                        <label>Password</label>
                        <input
                          type={showPassword ? "password" : "text"}
                          name="password"
                          placeholder="Password"
                          className="input103 w-100"
                          onChange={(e) => inputChange(e)}

                        />
                        <img
                          onClick={() => setShowPassword(!showPassword)}
                          src={showPassword ? require("../../images/update/eye-close.svg").default : require("../../images/update/eye.svg").default}
                          alt="img"
                        />
                      </div>
                    </aside>
                    <aside className="col-md-12 mb-3">
                      <div className="input_row password">
                        <label>Confirm Password</label>
                        <input
                          type={showCPassword ? "password" : "text"}
                          name="cpassword"
                          placeholder="Password"
                          className="input103 w-100"
                          onChange={(e) => inputChange(e)}
                        />
                        <img
                          onClick={() => setShowCPassword(!showCPassword)}
                          src={showCPassword ? require("../../images/update/eye-close.svg").default : require("../../images/update/eye.svg").default}
                          alt="img"
                        />
                      </div>
                      
                    </aside>
                    <aside className="col-md-12 my-2">
                      <h6 className="psw text-right">
                        {" "}
                        <a href="/forgotpassword">Forgot Password?</a>
                      </h6>
                    </aside>
                    <aside className="col-md-12 mt-3 sign_btn">
                      <a className="btn"
                        onClick={handleSumit}
                      >
                        Sign Up
                      </a>
                    </aside>
                    <aside className="col-md-12">
                      <div className="login_footer my-4 p-0 text-center ">
                        <div className="or_line mb-2">
                          OR{" "}
                          <img
                            src={
                              require("../../images/update/circle.svg")
                                .default
                            }
                            alt="img"
                          />
                        </div>
                      </div>
                    </aside>

                    
                    <aside className="col-sm-12">
                      <div className="row">
                      <aside className="col-sm-12">
                          <span className="google_btn">
                            <GoogleLogin
                              clientId={`${config.googleClientId}`}
                              render={(renderProps) => (
                                <a
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                  className="btn btn-google"
                                >
                                  <img
                                    src={
                                      require("../../images/update/google-logo.png")
                                        .default
                                    }
                                    alt="img"
                                  />
                                  Google
                                </a>
                              )}
                              redirectUri="https://big4healthapp.com/"
                              buttonText="Login"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy={"single_host_origin"}

                            />
                          </span>
                        </aside>
                       
                         <aside className="col-sm-12 mt-4">
                          <span className="btn-fb front-fb-btn">
                          <a
                            
                                  className="btn face-btn"
                                >
                                  <img
                                    src={
                                      require("../../images/update/facebook.svg")
                                        .default
                                    }
                                    alt="img"
                                  />
                                  <FacebookLogin
                              appId={config.fbAppId}
                              cssClass="btn"
                              fields="name,email,picture"
                              callback={responseFacebook}
                              textButton="Facebook"
                            />
                                </a>
                                
                          </span>
                        </aside>
                        <aside className="col-sm-12 mt-4">
                          <a href="/signin" className="new-btn">
                            Already have an account?{" "}
                            <a href="/signin" className="higlight-text">
                            Sign In
                            </a>
                          </a>
                        </aside>
                      </div>
                    </aside>
                  </div>
                </form>
              </div>
            </aside>
            <aside className="col-md-6 col-sm-6 pt-4">
              <div className="right-side-img">
                <img
                  src={require("../../images/update/right-doctor.png").default}
                  alt="img"
                />
              </div>
            </aside>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="verify-pop-space">
          <div className="email-verfication text-center">
            <img
              src={require("../../images/update/email.png").default}
              alt="img"
            />
            <h3 className="mt-5">Great! Let’s verify your email.</h3>
            <p className="mb-5 mdl-desc">
              A link to verify your email has been sent to the email address you
              provided.
            </p>
            <p>
              <b>Didn’t get the mail?</b>{" "}
              <Button className="higlight-text" onClick={() =>{
                if(!showbutton){
                resendVerification()}}} >
                Send it again
              </Button>
            </p>
          </div>
        </Modal.Body>
       
      </Modal>


      <Modal show={showSocialMedia} onHide={handleCloseSocialMedia} centered className="new-pop notranslate">
          <Modal.Header closeButton >
          </Modal.Header>
          <Modal.Body className="social-signin notranslate">
            <h4>Select Account type</h4>
            <div className="email-verfication notranslate">

              <Tabs
                defaultActiveKey="Professional"
                id="uncontrolled-tab-example notranslate"
                className="my-3 mb-4"

                onSelect={(e) => {  setUserType(e) }}
              >
                <Tab
                  eventKey="Professional"
                  title="Healthcare Provider"
                >
                </Tab>
                <Tab eventKey="Client" title="Client">
                </Tab>
              </Tabs>
              {userType == "Professional" &&
                <div className="input_row text-left notranslate">
                  <label>Select Account Type</label>
                  <div className="input103">
                  <select
                    className=" w-100 select-main"
                    name="userType"
                    onChange={handleChange}
                    value={userDetail?.accountType}
                  >
                    <option value={""}>Select account type</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                  </div>
                </div>}
              <div className="continue-btn notranslate">
                <Button type="button" disabled={userDetail.accountType == "" && userIDRef.current == ""} onClick={saveSocialLoginUserType}>Continue</Button>
              </div>

            </div>
          </Modal.Body>
        </Modal>

        
    </div>
  );
}
export default SignupMain;