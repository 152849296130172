import { useEffect, useRef, useState } from "react"
import { userService } from "../services"
import { config, appName } from "../config/config"
import { Button, Form, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2"
import ShareOnSocialMedia from "../Components/common/ShareOnSocialMedia"

function MedUserData(props) {
  const handleCloseSuccess = () => {
    setShowSuccess(false)
  }

  const [copy, setCopy] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  // let title = `Hey! Checkout this ${appName} post`;
  let title = `Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link.`
  const [share_url, setshare_url] = useState(null);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [userDetail, setUserDetail] = useState("")
  const play = useRef("")
  let history = useHistory()
  const [userid, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "")
  const [lgShow, setLgShow] = useState(false)
  const [cancelReason, setCancelReason] = useState("")

  useEffect(() => {
    if (props) {
      getMedUserDeatils(props?.data, props?.userId ? props?.userId : "")
    }
  }, [props])
  let copyUrl = window.location.host + `/big-me-details?id=${props?.data}`
  console.log(copyUrl)
  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);

  useEffect(() => {
    let location = copyUrl
    setshare_url(location);
  }, [])


  function shareOnWhatsapp(url, text) {
    const encodedUrl = encodeURIComponent(url);
    const encodedText = encodeURIComponent(text);
    const navUrl = `https://api.whatsapp.com/send?text=${encodedText}%0A${encodedUrl}`;
    window.open(navUrl, '_blank');
  }

  /// getdetails api
  function getMedUserDeatils(id, userid) {
    userService.getmed_detail(id, userid).then((res) => {
      setUserDetail(res?.data?.getDetailAfteViews)
    })
      .catch((Error) => {
      })
  }

  function postLikeAndDislike(id) {
    const params = {
      post_id: id,
    }
    setIsLoading(true)
    userService.postLike(params).then((res) => {
      setIsLoading(false)
      getMedUserDeatils(id, userid)
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function handleContextMenu(event) {
    event.preventDefault();
  }


  const shareOnWhatsApp = async (url, title) => {
    const os = getOperatingSystem();
    console.log("Operating System:", os);

    if (os === "Windows" || os === "Linux" || os === "MacOS") {
      if (await isWhatsAppInstalled()) {
        console.log("WhatsApp app is available. Sharing on WhatsApp app.");
        try {
          await navigator.share({
            title,
            url,
          });
          console.log("Shared successfully!");
        } catch (error) {
          console.error('Error sharing on WhatsApp:', error);
          fallbackToWhatsAppWeb(url, title);
        }
      } else {
        console.log("WhatsApp app is not available. Redirecting to WhatsApp Web.");
        fallbackToWhatsAppWeb(url, title);
      }
    } else {
      console.log("Unsupported operating system.");
    }
  };

  const getOperatingSystem = () => {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes("win")) {
      return "Windows";
    } else if (platform.includes("linux")) {
      return "Linux";
    } else if (platform.includes("mac")) {
      return "MacOS";
    } else {
      return "Unknown";
    }
  };

  const isWhatsAppInstalled = async () => {
    return new Promise((resolve) => {
      const isWhatsApp = /WhatsApp/.test(navigator.userAgent);
      resolve(isWhatsApp);
    });
  };

  const fallbackToWhatsAppWeb = (url, title) => {
    console.log("WhatsApp app is not available. Opening WhatsApp link.");
    window.open(`https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`, '_blank');
  };

  // Report post
  function postReport(id) {
    const params = {
      post_id: userDetail?._id,
      reason: cancelReason
    }
    setLgShow(true)
    userService.reportPost(params).then((res) => {
      if (res?.data?.status === 200) {
        console.log("res========", res)
        getMedUserDeatils(id, userid)
        Swal.fire("Success", "Post reported successfully.", "success").then((r) => {
          setLgShow(false)
        })


      }
    })
      .catch((error) => {
        setLgShow(false)
        console.log("error======", error)
      })

  }


  const [onplaying, setOnPlaying] = useState(false)
  let pathname = window.location.pathname?.split("/")[1];
  return (
    <>
      <div className="profile-right-body">
        <div className="container">
          <div className="back-btn-sec mb-3 d-flex align-item-center justify-content-between flex-wrap">
            <Button onClick={(e) => {
              if (pathname == "profilenew") {
                history.push("/profilenew")
              }
              else if (pathname == "profile") {
                history.push(`/profile/${userDetail?.user?.userSlug}`)
              }
            }}
              className="btn back-btn"><i className="fa  fa-angle-left"></i>Back to Listing</Button>


            <div>
              <button className="join-button mt-0 mr-2" onClick={(e) => setShowSuccess(true)} >
                <i class="fa fa-share-alt mr-1" aria-hidden="true"></i>  Share
              </button>
              {userDetail?.is_reported == false && userDetail.user._id !== JSON.parse(localStorage.getItem("user"))?._id && JSON.parse(localStorage.getItem("user")) &&
                <button className="join-button mt-1" onClick={(e) => {
                  let confirmBox = Swal.fire({
                    title: "Are you sure?",
                    text: "Want to Report this Post.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    cancelButtonText: "Cancel",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if (localStorage.getItem("token")) {
                        setLgShow(true)
                      } else {
                        Swal.fire("Warning", "To perform this action, login is required.", "warning").then((res) => {
                          window.location.href = "/signin";
                        });
                      }
                    }
                  });
                }} >
                  <i class="fa fa-flag mr-1" aria-hidden="true"></i>   Report
                </button>
              }
            </div>


          </div>
          <div className="big-med-details-wrap">
            <div className="big-med-main-img big-med-main-vid">
              {userDetail?.media_type == "video" &&
                <div className="big-med-main-img big-med-main-vid">
                  <video
                    onContextMenu={handleContextMenu}
                    controlsList="nodownload"
                    width="100%"
                    height="100%"
                    ref={play}
                    muted
                    loop
                    onPlay={(e) => setOnPlaying(true)}
                    onEnded={(e) => setOnPlaying(false)}
                    preload='auto'
                    playsinline
                    controls
                    poster={userDetail?.thumbnail_icon}
                  >
                    <source
                      src={userDetail?.media[0]}
                      type="video/mp4"
                    />
                    <source
                      src={userDetail?.media[0]}
                      type="video/ogg"
                    />
                    <source
                      src={userDetail?.media[0]}
                      type="video/webm"
                    />
                  </video>
                  {
                    !onplaying && <img className="video-btn" onClick={(e) => play.current.play()} src={require("../images/video-play.svg").default} alt="img" />}
                </div>}

            </div>
            {userDetail?.media_type == "pdf" && <div className="big-med-main-img big-med-main-pdf ">
              <a href={userDetail?.media[0]} target='_blank'><img src={userDetail?.media_type == "pdf" && userDetail?.thumbnail_icon && userDetail?.thumbnail_icon != "undefined" ? userDetail?.thumbnail_icon : require("../images/big-med-pdf.jpg").default} alt="img" /></a>
            </div>}
            {userDetail?.media_type == "voice" && <div className="big-med-main-img big-med-main-audio">

              <a href="#"><img src={userDetail?.media_type == "voice" && userDetail?.thumbnail_icon && userDetail?.thumbnail_icon != "undefined" ? userDetail?.thumbnail_icon : require("../images/big-med-audio.jpg").default} alt="img" /></a>
              <div class="player">
                <audio controls autoplay>
                  <source src={userDetail?.media[0]} type="audio/ogg" />
                  <source src={userDetail?.media[0]} type="audio/mpeg" />
                </audio>
              </div>

            </div>}
            {userDetail?.media_type == "image" && <div className="big-med-main-img profile-big-me">
              {userDetail?.media?.length > 1 ? (
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  speed={700}
                  loop={true}
                  pagination={{ clickable: true }}
                >
                  {userDetail?.media?.map((image, key) => (
                    <SwiperSlide key={key}>
                      <a href="#">
                        <img className="" src={image} alt="img" />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <a href="#">
                  <img className="" src={userDetail?.media[0]} alt="img" />
                </a>
              )}
            </div>}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="big-med-details-text">
              <div className="big-med-text mb-4">
                <div className="video-like">

                  <img onClick={() => {
                    if (localStorage.getItem("token")) {
                      postLikeAndDislike(userDetail?._id)
                    } else {
                      Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                        window.location.href = "/signin";
                        let redirectUri = window.location.pathname + window.location.search;
                        localStorage.setItem("redirectUri", redirectUri);
                      })

                    }
                  }} src={require(userDetail?.isLikedByMe == false ? "../images/update/like.svg" : "../images/update/chat-icon-fill.svg").default} alt="img" />

                  <p> {userDetail?.likeCount ? userDetail?.likeCount : ""} likes</p>
                </div>
                <h4>{userDetail?.title ? userDetail?.title : ""}</h4>

                <p><span className="channel-views">{userDetail?.views ? userDetail?.views : 0} </span>  <span>|</span> <span className="channel-time"> {userDetail?.timeAgo}</span></p>
                <div className=""></div>
              </div>
              <div className="big-med-icon-text d-flex">
                <div className="big-med-thumbnail">
                  {userDetail?.user && userDetail?.user?.profilePicture !== "undefined" ? <img src={userDetail && userDetail?.user?.profilePicture ? userDetail?.user?.profilePicture : require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" /> : <img src={require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" />}
                </div>

                <div className="big-med-text mr-4">
                  <p className="channel-name">{userDetail?.user?.name}</p>
                  {localStorage.getItem("userType") == "Professional" && <p><span>{userDetail?.user?.professionalType}</span></p>}
                </div>
                <div className="details-cetagory d-flex"><span>Category: <b>{userDetail?.category?.name}</b></span><span>|</span><span>Severity Level: <b>{userDetail?.severity_level}</b></span><span>|</span>{localStorage.getItem("userType") == "Professional" && <span>Accepting New Patient: <b>{userDetail?.looking_provider == false ? "No" : "Yes"}</b></span>}{localStorage.getItem("userType") == "Client" && <span>Looking for a healthcare provider: <b>{userDetail?.healthcare_provider == false ? "No" : "Yes"}</b></span>}{localStorage.getItem("userType") == "Professional" && <span>Hospital/Clinic Name :<b>{userDetail?.HospitalName ? userDetail?.HospitalName : "N/A"}</b></span>}</div>
              </div>
            </div>

          </div>
          <div className="description mt-3 mb-5">
            <p>{userDetail?.description}</p>
          </div>
        </div>

      </div>
      <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="email-verfication text-center">
            <h3 className="mt-4 mb-3 black-text">Share Details </h3>

            <p className="black-text">
              <span> Share this new post with 10 friends to expand your network!</span>
            </p>
            <div className="copy-profile d-flex mb-4 copy-link-area">
              {copy ? (
                <>
                  <span style={{ fontSize: "12px", color: "#ff7750" }}>
                    Link Copied
                  </span>
                  <br></br>
                </>
              ) : (
                <>
                  <span style={{ fontSize: "10px" }}></span>
                  <br></br>
                </>
              )}
              <input
                type="text"
                value={copyUrl}
                disabled={true}
                style={{ fontSize: "12px" }}
              />
              <CopyToClipboard text={copyUrl} onCopy={() => setCopy(true)}>
                <i
                  className="fa fa-clipboard Demo__some-network__share-button"
                  title="Copy to clipboard"
                  aria-hidden="true"
                  style={{ fontSize: "19px", cursor: "pointer" }}
                ></i>
              </CopyToClipboard>
            </div>
            <div className="congrats-social-icons mb-2">
              <ShareOnSocialMedia url={copyUrl} text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={lgShow}

        centered className='quiz-pop appointment-box cancel-order-popup'>
        <Modal.Header
        >
          <Modal.Title><h4><b>Report Post</b></h4></Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='appointment-box text-center'>
            <div className="Signup_box text-center contact-main-box">
              <Form className="text-left mt-3 cancel-order-area">
                <label className='text-left'>
                  GIVE A REASON<span className="mandatory"></span>
                </label>
                <textarea
                  onChange={e => setCancelReason(e.target.value)}
                  value={cancelReason}
                  maxLength={200}
                  placeholder="Enter Reason">
                </textarea>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none m-auto">
          <Button className='btn notnowbtn' onClick={() => setLgShow(false)}>NO</Button>
          <Button className='btn quiznow-btn'
            onClick={() => {
              if (localStorage.getItem("token")) {
                postReport(userDetail?._id)
              } else {
                Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                  window.location.href = "/signin";
                  let redirectUri = window.location.pathname + window.location.search;
                  localStorage.setItem("redirectUri", redirectUri);
                })

              }
            }}
          >SUBMIT</Button>

        </Modal.Footer>
      </Modal>
    </>
  )

}
export default MedUserData;