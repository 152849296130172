import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Download = () => {
    return (
        <>
            <section className="download-app">
                <div className="container">
                    <Row className="align-items-center">
                        <Col md={5}>
                            <div className="business-sec-img">
                                <img src={require("../../images/update/download-app.png").default} alt="img" />
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="health-app-text">
                                <h1>Download the BIG4 Health App</h1>
                                <p>Join the millions of users already benefiting from this comprehensive and personalized approach to managing chronic conditions. Take
                                    control of your health today with the BIG4 Health App.</p>
                                <div className="play-apple-store">
                                    <a href="https://apps.apple.com/us/app/big4health/id1517973481" target="_blank"><img src={require("../../images/update/apple-store.png").default} alt="img" /></a>
                                    <a  href="https://play.google.com/store/apps/details?id=com.big4healthapp" target="_blank"><img src={require("../../images/update/play-store.png").default} alt="img" /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default Download;    