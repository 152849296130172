import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { userService } from "../../services";
import Loader from "./Loader";
import MaskedInput from "react-text-mask";
import csc from "country-state-city";
import { Country, State, City, getAllCountries } from "country-state-city";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import socket, { QB, APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY } from "../../config/socket";
import { uploadFileToS3 } from "../../config/AwsConfig";





const Signup = () => {
  let redirectUri = localStorage.getItem("redirectUri")


  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.href = "/signin";
    localStorage.clear()
  }
  const handleShow = () => setShow(true);
  const [address, setAddress] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [showHide, setShowHide] = useState(false);
  const [acceptPrivacyTerms, setAcceptPrivacyTerms] = useState(false);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpType, setSignUpType] = useState("Standard");
  const [fbId, setFbId] = useState("");
  const [country, setCountry] = useState("-1");
  const [selCountry, setSelCountry] = useState("");
  const [userDetail, setUserDetails] = useState({
    "firstName": "", "experience": "", "speciality": "", "email": "", "linkedInUrl": "", "websiteUrl": "",
    "profilepic": "", "mobileNumber": "", "Docs": "", "Docs2": "", "languages": "", "city": "", "zipCode": "", "country": "", "bio": "", "psuedoCode": "", "age": "", "gender": "", "ReceiveHeath": false, "termsAndConditions": "", "acceptPolity": "false", "acceptnewPatient": true
  })
  const [profilePicURL, setProfilePicURL] = useState()
  const KeyDownPhone = [" ", "`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
  const KeyDowns = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "<", ">", ".", "\\", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
  const KeyDown = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
  const KeyDowns2 = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "<", ">", ".", "\\"]
  function inputChange(e) {
    let { name, value } = e.target;
    setUserDetails({ ...userDetail, [name]: value });
  }

  const { transcript, resetTranscript } = useSpeechRecognition();
  const bottomRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const inputRef = useRef(null);
  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [isPhone, setIsPhone] = useState(false)
  const [isemail, setIsEmail] = useState(false)
  const [userType, setUserType] = useState("")



  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/signin";
    }
    getProfileList()
  }, [])

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    let token = localStorage.getItem("token")
    let params = { userId: user_id, token: token }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        console.log("userData------", userData)
        setUserType(userData?.userType)
        setUserDetails(userData)
        setIsPhone(userData?.mobileNumber ? true : false)
        setIsEmail(EMAILREGEX.test(userData?.email))
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
      }
    }).catch((error) => {
    });
  }

  console.log("Speciality------", userDetail?.speciality)



  function handleSubmit(e) {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    e.preventDefault();
    if (!userDetail?.firstName || userDetail?.firstName?.trim() === "") {
      Swal.fire("Warning", "Please enter Full Name.", "warning");
      return
    }
    else if ((!userDetail?.mobileNumber || userDetail?.mobileNumber?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Please enter Phone Number.", "warning");
      return
    }
    else if ((!userDetail?.speciality || userDetail?.speciality?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Please enter Speciality.", "warning");
      return
    }
    else if (!userDetail?.email) {
      Swal.fire("Warning", "Please enter email address..", "warning");
      return
    }
    else if ((!userDetail?.experience || userDetail?.experience === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Please enter experience.", "warning");
      return
    }

    else if (!userDetail?.languages || userDetail?.languages?.trim() === "") {
      Swal.fire("Warning", "Please enter language.", "warning");
      return
    }

    else if (!userDetail?.city || userDetail?.city?.trim() === "") {
      Swal.fire("Warning", "Please enter city.", "warning");
      return
    }

    else if (!userDetail?.zipCode || userDetail?.zipCode === "") {
      Swal.fire("Warning", "Please enter zip code.", "warning");
      return
    }

    else if (!userDetail?.country || userDetail?.country === "") {
      Swal.fire("Warning", "Please select country.", "warning");
      return
    }

    // else if (userDetail?.linkedInUrl &&  !urlPattern.test(userDetail?.linkedInUrl)) {
    //   Swal.fire("Warning", "Please enter a valid LinkedIn URL.", "warning");
    //   return;
    // }
    // else if(userDetail?.websiteUrl && !urlPattern.test(userDetail?.websiteUrl)){
    //   Swal.fire("Warning", "Please enter valid Website url.", "warning");
    //   return
    // }
    else if (userDetail?.websiteUrl && userDetail?.websiteUrl !== "null" && !urlPattern.test(userDetail?.websiteUrl)) {
      Swal.fire("Warning", "Please enter valid Website url.", "warning");
      return
    }
    else if (userDetail?.linkedInUrl && userDetail?.linkedInUrl !== "null" && !urlPattern.test(userDetail?.linkedInUrl)) {
      Swal.fire("Warning", "Please enter a valid LinkedIn URL.", "warning");
      return;
    }

    else if ((!userDetail?.bio || userDetail?.bio?.trim() === "") && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Enter Short Bio.", "warning");
      return
    }

    if ((!userDetail?.age || userDetail?.age === "") && localStorage.getItem("userType") == "Client") {
      Swal.fire("Warning", "Please enter age.", "warning");
      return
    }
    if ((!userDetail?.gender || userDetail?.gender?.trim() === "") && localStorage.getItem("userType") == "Client") {
      Swal.fire("Warning", "Please enter gender.", "warning");
      return
    }
    if (!userDetail.termsAndConditions && localStorage.getItem("userType") == "Client") {
      Swal.fire("Warning", "Please accept term and conditions.", "warning");
      return
    }
    if (!userDetail.acceptnewPatient && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "Are you currently accepting new patients?", "warning");
      return
    }
    if (!userDetail.acceptPolity && localStorage.getItem("userType") == "Professional") {
      Swal.fire("Warning", "To continue, please accept the policy", "warning");
      return
    }
    else {
      createProfile()
    }
  }

  useEffect(() => {
    if (isListening) {
      setUserDetails({ ...userDetail, bio: transcript })
      const textInputEl = inputRef.current;
      textInputEl.scrollTop = textInputEl?.scrollHeight;
    }
  }, [transcript])


  const getSpeechLanguage = () => {

    return localStorage.getItem("userLaguage") || 'en-US'; // Default to English if not found
  };
  const browserLanguage = navigator.language || navigator.userLanguage;
  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: browserLanguage
    });
    setTimeout(stopHandle, 120000)
  };
  const stopHandle = () => {
    setIsListening(false);
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();

  };
  const handleReset = () => {
    setUserDetails({ ...userDetail, bio: "" })
    stopHandle();
    resetTranscript();
  };








  async function handleProfilePicData(e) {
    let profileImageUrl = '';
    const imageFile = e.target.files[0];
    if (imageFile) {
      let profileImageData = await uploadFileToS3(imageFile, "profile");
      profileImageUrl = profileImageData?.Location;
    }

    if (imageFile) {
      if (imageFile.type.startsWith('image/')) {
        setUserDetails({ ...userDetail, profilepic: profileImageUrl });
        setProfilePicURL(URL.createObjectURL(imageFile));
      } else {
        Swal.fire("Warning", 'Please select a valid image file.', "warning");
      }
    }
  }


  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }








  async function createProfile() {
    var formData = new FormData();
    // formData.append("name", userDetail.firstName);
    // formData.append("speciality", userDetail.speciality);
    // formData.append("document", userDetail.Docs);
    // formData.append("license", userDetail.Docs2);
    // formData.append("languages", userDetail.languages);
    // formData.append("bio", userDetail.bio);
    // formData.append("experience", userDetail.experience);
    // formData.append("mobileNumber", userDetail.mobileNumber);
    // formData.append("zipCode", userDetail.zipCode);
    // formData.append("country", userDetail.country);
    // formData.append("city", userDetail.city);
    // formData.append("email", userDetail.email);
    // formData.append("profilePicture", userDetail.profilepic);
    // formData.append("linkedInUrl", userDetail.linkedInUrl);
    // formData.append("websiteUrl", userDetail.websiteUrl);
    // formData.append("accetpatients", userDetail.acceptnewPatient ? userDetail.acceptnewPatient : "");
    // formData.append("acceptpolicy", userDetail.acceptPolity ? userDetail.acceptPolity : "");
    // if (localStorage.getItem(("userType")) == "Client") {
    //   formData.append("pseudoName", userDetail.psuedoCode ? userDetail.psuedoCode : "");
    //   formData.append("recievePromotion", userDetail.ReceiveHeath ? userDetail.ReceiveHeath : "")
    //   formData.append("termsAndConditions", userDetail.termsAndConditions ? userDetail.termsAndConditions : "")
    //   formData.append("age", userDetail.age ? userDetail.age : "");
    //   formData.append("gender", userDetail.gender)
    // }
    if (userType == "Professional") {
      formData.append("speciality", userDetail.speciality);
      formData.append("mobileNumber", userDetail.mobileNumber);
      formData.append("bio", userDetail.bio);
      formData.append("document", userDetail.Docs);
      formData.append("license", userDetail.Docs2);
      formData.append("experience", userDetail.experience);
      formData.append("accetpatients", userDetail.acceptnewPatient ? userDetail.acceptnewPatient : "");
      formData.append("acceptpolicy", userDetail.acceptPolity ? userDetail.acceptPolity : "");
      formData.append("linkedInUrl", userDetail.linkedInUrl);
      formData.append("websiteUrl", userDetail.websiteUrl);
    }
    if (userType == "Client") {
      formData.append("pseudoName", userDetail.psuedoCode ? userDetail.psuedoCode : "");
      formData.append("age", userDetail.age ? userDetail.age : "");
      formData.append("gender", userDetail.gender)
      formData.append("recievePromotion", userDetail.ReceiveHeath ? userDetail.ReceiveHeath : "")
      formData.append("termsAndConditions", userDetail.termsAndConditions ? userDetail.termsAndConditions : "")
    }
    formData.append("name", userDetail.firstName);
    formData.append("country", userDetail.country);
    formData.append("city", userDetail.city);
    formData.append("profilePicture", userDetail.profilepic);
    formData.append("languages", userDetail.languages);
    formData.append("zipCode", userDetail.zipCode);
    setIsLoading(true)
    await userService.create_profile(formData).then((res) => {
      if (res?.data?.status === 200) {
        var email = res?.data?.data[0]?.email;
        var password = 'big4healthapp';
        var params = { login: email, password: password };
        localStorage.setItem("user", JSON.stringify(res.data.data[0]));

        QB.createSession(params, async function (error, result) {
          await QB.login(params, function (error, result) {
          });
        });
        if (res?.data?.data[0]?.userType == "Professional" && res?.data?.data[0]?.admin_status != "Approved") {
          setShow(true)
        }
        if (localStorage.getItem("redirectUri")) {
          window.location.href = redirectUri;
        }
        else {
          window.location.href = "/";
          setIsLoading(false)
        }
        // setIsLoading(false)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  // --------------AutoComplete-----------
  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};

    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }


  function readURL(input) {
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        setUserDetails({ ...userDetail, profilepic: input.files[0] });
        var reader = new FileReader();
        reader.onload = function (e) {
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  function checkfile(file_img) {
    var get_ext = file_img.name.split(".");

    var exts = ["png", "jpg", "jpeg", "gif"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return true;
    } else {
      alert("Please select a valid image file");
      return false;
    }
  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      alert("File size exceeds 50 MB");
      return false;
    } else {
      return true;
    }
  }

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const inputChangefile = async (e) => {
    let profileImageUrl = "";
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const allowedExtensions = [".xls", ".xlsx", ".doc", ".docx", ".pdf", ".ppt", ".txt"];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (file) {
        let profileImageData = await uploadFileToS3(file, "profile");
        profileImageUrl = profileImageData?.Location;
      }

      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        Swal.fire("Warning", "Please upload a valid document (Excel, Word, PDF, PPT, Notes, Notepad).", "warning");
        e.target.value = null; // Clear the file input
        return;
      }
      setUserDetails({
        ...userDetail,
        [name]: profileImageUrl,
      });

      // Get the filename and set it as the placeholder value
      const filename = file.name;
      e.target.parentNode.querySelector('.upload-text').innerText = filename;
    }
  };


  const handleCancelUpload = () => {
    setProfilePicURL(null);
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <section className="bg_section signup_section redesign-profile">
        <div className="container-fluid">
          <div className="row">
            <aside className="col-md-12">
              <div className="Signup_box">
                <h4 className="mb-4">CREATE PROFILE</h4>
                <div className="row border-1">
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <div className="camera_box mt-3" style={{ position: "relative" }}>
                        {!profilePicURL ? (
                          <label htmlFor="fileInput" className="camera_icon">
                            <FontAwesomeIcon icon={faCamera} size="2x" />
                          </label>
                        ) : (
                          <div>
                            <img data-toggle="tooltip" data-placement="top" title={userDetail?.profilepic?.name} src={profilePicURL} alt="Profile Preview" />
                            <a className="cancel" onClick={() => handleCancelUpload()}>
                              <FontAwesomeIcon icon={faTimes} />
                            </a>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleProfilePicData(e)}
                        onClick={(e) => (e.target.value = null)}
                        id="fileInput"
                        style={{ display: 'none' }} // Hide the input element
                      />
                      <a className="upload" onClick={() => document.getElementById('fileInput').click()}>
                        Upload Picture
                      </a>
                      <p className="disclaimer">
                        <b>*Disclaimer:</b> To keep your identity private, please use an avatar image.
                      </p>
                    </div>
                  </aside>
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <form className="">
                        <div className="row frist_step contact-form-area ">
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              Full Name<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="firstName"
                                name="firstName"
                                placeholder="Enter Full Name"
                                className="input103 w-100"
                                value={userDetail?.firstName}
                                onChange={(e) => inputChange(e)}
                                maxLength={50}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}

                              />
                            </div>
                          </aside>
                          {console.log("userDetail?.MobileNumber======", userDetail)}
                          {localStorage.getItem("userType") == "Professional" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Phone Number<span className="blue-asterisk">*</span>
                              </label>
                              <div className="input_row">
                                <input
                                  type="mobileNumber"
                                  name="mobileNumber"
                                  placeholder="Enter Phone Number"
                                  className="input103 w-100"
                                  readOnly={isPhone}
                                  value={userDetail?.mobileNumber}
                                  onKeyDown={(e) => KeyDownPhone.includes(e.key) && e.preventDefault()}
                                  onChange={(e) => inputChange(e)}
                                  maxLength={16}

                                />

                              </div>
                            </aside>}

                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              Email Address<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="email"
                                name="email"
                                placeholder="Enter Email Address"
                                className="input103 w-100"
                                value={userDetail?.email !== undefined && userDetail?.email !== "undefined" ? userDetail?.email : ""}
                                readOnly={isemail}
                                onChange={(e) => inputChange(e)}
                                maxLength={50}
                              />
                            </div>
                          </aside>

                          {localStorage && localStorage?.getItem("userType") == "Professional" && <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              Speciality<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="speciality"
                                name="speciality"
                                placeholder="ex. Radiology, Pediatrics"
                                className="input103 w-100"
                                value={userDetail?.speciality}
                                maxLength={50}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}
                                onChange={(e) => inputChange(e)}

                              />
                            </div>
                          </aside>}

                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Pseudo Name <a href="#" className="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i> <span class="tooltiptext">Your profile will only display the pseudo name, and no other personal information will be visible to other users.</span></a>
                                <span className="optional">(Optional)</span>
                              </label>
                              <div className="input_row">
                                <input
                                  type="psuedoCode"
                                  name="psuedoCode"
                                  placeholder="Enter Pseudo Name"
                                  className="input103 w-100"
                                  value={userDetail?.psuedoCode}
                                  maxLength={25}
                                  onChange={(e) => inputChange(e)}

                                />
                              </div>
                            </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>Years of Experience<span className="blue-asterisk">*</span></label>
                              <div className="input_row">
                                <input
                                  type="number"
                                  name="experience"
                                  placeholder="Enter Years of Experience"
                                  className="input103 w-100"
                                  min={0}
                                  value={userDetail?.experience}
                                  maxLength={2}
                                  onKeyDown={(e) => KeyDown.includes(e.key) && e.preventDefault()}
                                  onChange={(e) => inputChange(e)}
                                  onPaste={handlePaste}

                                />
                              </div>
                            </aside>}


                          {localStorage && localStorage?.getItem("userType") === "Professional" && (
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Verification Document
                                <span className="optional">(Optional)</span>
                              </label>
                              <div className="input_row d-flex justify-content-between flex-direction-col">
                                <div className="upload-document upload-document-first">
                                  <input
                                    type="file"
                                    accept=".xls, .xlsx, .doc, .docx, .pdf, .ppt, .txt"
                                    name="Docs"
                                    placeholder={!userDetail?.Docs ? "Upload License" : userDetail?.Docs}
                                    className="input103 w-100"
                                    onChange={inputChangefile}
                                  />
                                  {!userDetail?.Docs ? <span className="upload-text">Upload License</span> : <span className="upload-text">{userDetail?.Docs}</span>}
                                  <img
                                    src={require("../../images/update/upload.svg").default}
                                    alt="img"
                                  />
                                </div>

                                <div className="upload-document">
                                  <input
                                    type="file"
                                    accept=".xls, .xlsx, .doc, .docx, .pdf, .ppt, .txt"
                                    name="Docs2"
                                    placeholder={!userDetail?.Docs2 ? "Upload Other ID" : userDetail.Docs2}
                                    className="input103 w-100"
                                    onChange={inputChangefile}
                                  />
                                  {!userDetail?.Docs2 ? <span className="upload-text">Upload Other ID</span> : <span className="upload-text">{userDetail.Docs2}</span>}
                                  <img
                                    src={require("../../images/update/upload.svg").default}
                                    alt="img"
                                  />
                                </div>
                              </div>
                            </aside>
                          )}

                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Age
                                <span className="optional"></span>
                              </label>
                              <div className="input_row icon-down-area">

                                <Form>
                                  <Form.Group
                                    controlId="exampleForm.ControlSelect1"
                                    className="input103"
                                  >
                                    <Form.Control
                                      as="select"
                                      name="age"
                                      value={userDetail?.age}
                                      onChange={(e) => inputChange(e)}
                                    >
                                      <option default className="dflt">
                                        Select Age
                                      </option>
                                      {range(10, 100).map((val) => {
                                        return <option value={val}>{val}</option>;
                                      })}
                                    </Form.Control>
                                  </Form.Group>
                                </Form>
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                              </div>
                            </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                              <label>
                                Gender<span className="optional"></span>
                              </label>
                              <div className="input_row icon-down-area">

                                <Form>
                                  <Form.Group
                                    controlId="exampleForm.ControlSelect1"
                                    className="input103"
                                  >
                                    <Form.Control
                                      as="select"
                                      name="gender"
                                      value={userDetail?.gender}
                                      onChange={(e) => inputChange(e)}
                                    >
                                      <option value="">Select Gender</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Form>
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                              </div>
                            </aside>}
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>Languages<span className="blue-asterisk">*</span></label>
                            <div className="input_row">
                              <input
                                type="languages"
                                name="languages"
                                placeholder="ex. English, Portuguese"
                                className="input103 w-100"
                                value={userDetail?.languages}
                                onChange={(e) => inputChange(e)}
                                maxLength={50}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}

                              />
                            </div>
                          </aside>
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>
                              City<span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="city"
                                name="city"
                                placeholder="Enter City"
                                className="input103 w-100"
                                value={userDetail?.city}
                                onKeyDown={(e) => KeyDowns.includes(e.key) && e.preventDefault()}
                                onChange={(e) => inputChange(e)}
                                maxLength={30}
                              />
                            </div>
                          </aside>
                          <aside className="col-md-12 col-lg-6 col-xl-3 col mb-3 text-left">
                            <label>
                              ZIP Code
                              <span className="blue-asterisk">*</span>
                            </label>
                            <div className="input_row">
                              <input
                                type=" zipCode"
                                name="zipCode"
                                placeholder="Enter Zip Code"
                                className="input103 w-100"
                                value={userDetail?.zipCode}
                                onKeyDown={(e) => KeyDowns2.includes(e.key) && e.preventDefault()}
                                maxLength={10}
                                onChange={(e) => inputChange(e)}

                              />
                            </div>
                          </aside>
                          <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>Country<span className="optional"></span> <span className="blue-asterisk">*</span></label>
                            <div className="input_row input103">
                              <select
                                className="w-100 select-main"
                                name="country"
                                value={userDetail?.country}
                                onChange={(e) => inputChange(e)}
                              >
                                <option value={""}>Select Country</option>
                                {Country.getAllCountries().map((c) => {
                                  return (
                                    <option value={c.name}>{c.name}</option>
                                  )
                                })}

                              </select>
                            </div>
                          </aside>


                          {localStorage && localStorage?.getItem("userType") == "Professional" && <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>Website URL</label>
                            <div className="input_row">
                              <input
                                type="text"
                                name="websiteUrl"
                                placeholder="https://www.example.com"
                                className="input103 w-100"
                                // value={userDetail?.websiteUrl}
                                value={userDetail?.websiteUrl && userDetail?.websiteUrl !== "null" ? userDetail?.websiteUrl : ""}
                                // value={userDetail?.websiteUrl == null ? userDetail?.websiteUrl :""}
                                onChange={(e) => inputChange(e)}
                              />
                            </div>
                          </aside>}

                          {localStorage && localStorage?.getItem("userType") == "Professional" && <aside className="col-md-12 col-lg-6 col-xl-3 col-12 mb-3 text-left">
                            <label>LinkedIn URL</label>
                            <div className="input_row">
                              <input
                                type="text"
                                name="linkedInUrl"
                                placeholder="https://www.linkedin.com/in/example"
                                className="input103 w-100"
                                // value={userDetail?.linkedInUrl ==null ? userDetail?.linkedInUrl :""}
                                // value={userDetail?.linkedInUrl}
                                value={userDetail?.linkedInUrl && userDetail?.linkedInUrl !== "null" ? userDetail?.linkedInUrl : ""}
                                onChange={(e) => inputChange(e)}
                              />
                            </div>
                          </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-12 col-lg-6 col-xl-12 col-12 mb-3 mb-3 text-left">
                              <div className="input_row">
                                <label>Brief Bio<span className="blue-asterisk">*</span></label>
                                <textarea
                                  className="w-100 select-main letter-bio-area"
                                  placeholder="Enter Short Bio"
                                  name="bio"
                                  ref={inputRef}
                                  maxLength={500}
                                  value={userDetail?.bio}
                                  onChange={(e) => inputChange(e)}
                                ></textarea>

                              </div>

                            </aside>

                          }

                          {localStorage && localStorage?.getItem("userType") == "Client" &&
                            <aside className="col-md-12 axxpt_row my-4">
                              {localStorage && localStorage?.getItem("userType") == "Client" &&
                                <label className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="ReceiveHeath"
                                    className="remem mr-2"
                                    defaultChecked={userDetail?.ReceiveHeath}
                                    value={!userDetail.ReceiveHeath}
                                    onChange={(e) => inputChange(e)}

                                  />
                                  Receive Health Related News Promotions
                                </label>}

                              {localStorage && localStorage?.getItem("userType") == "Client" &&
                                <label className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="termsAndConditions"
                                    className="remem mr-2"
                                    defaultChecked={userDetail?.termsAndConditions}
                                    value={!userDetail.termsAndConditions}
                                    onChange={(e) => inputChange(e)}
                                  />{" "}
                                  Accept Our{" "}
                                  <a
                                    className="mx-1"
                                    href="/privacy-policy"
                                    target="_blank"
                                  >
                                    {" "}
                                    Privacy Policy
                                  </a>{" "}
                                  and{" "}
                                  <a className="ml-1" href="/terms" target="_blank">
                                    {" "}
                                    Terms and Conditions
                                  </a>
                                </label>}
                            </aside>}

                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-12 text-left">
                              <div className="input_row d-flex checkbox-area">
                                <label>
                                  Accept New Patients
                                  <span className="blue-asterisk">*</span>
                                </label>
                                <Form className="ml-3">
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="mb-3"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="acceptnewPatient"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        checked={(userDetail?.acceptnewPatient == "true")}
                                        value={!(userDetail?.acceptnewPatient == false)}
                                        onChange={(e) => inputChange(e)}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="acceptnewPatient"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        checked={(userDetail?.acceptnewPatient == "false")}
                                        value={(userDetail?.acceptnewPatient == false)}
                                        onChange={(e) => inputChange(e)}
                                      />
                                    </div>
                                  ))}

                                </Form>
                              </div>
                            </aside>}
                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-12 mb-3 text-left">
                              <Form className="checkbox-area">
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label={
                                        <div>
                                          Accept Policy
                                          <span className="blue-asterisk">*</span>
                                        </div>
                                      }
                                      name="acceptPolity"
                                      type={type}
                                      id={`inline-${type}-2policy`}

                                      checked={(userDetail?.acceptPolity == "true")}
                                      value={(userDetail?.acceptPolity == "true" ? "false" : "true")}
                                      onClick={(e) => { inputChange(e) }}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </aside>}

                        </div>
                      </form>
                    </div>
                    <div className="next_btn mt-2">
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn w-25"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </aside>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="email-verfication text-center">
            <img
              src={require("../../images/update/check.png").default}
              alt="img"
            />
            <h3 className="mt-5">Successful</h3>
            <p>
              Account created successfully and submitted for admin approval. You
              will be notified by email once your account is verified.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default Signup; 